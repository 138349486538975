@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.pagination {
    margin-bottom: 0;

    .page-item {
        margin: 0 8px 0 0;
        width: 36px;

        &:focus {
            outline: none;
        }

        .page-link {
            cursor: pointer;
            padding: .5rem 0;
            text-align: center;
            width: 36px;

            &.fa-angle-double-right::before {
                padding-left: 3px;
            }
            &.fa-angle-double-left::before {
                padding-right: 3px;
            }
            &:hover {
                background: $alternative-text-light-color;
                border-radius: 50%;
                color: $charcoal-grey;
            }
        }


        &.active .page-link {
            background: none;
            pointer-events: none;
            color: $deep-sky-blue;
        }

        .page-link,
        &.disabled .page-link {
            border: none;
            background: transparent;
            color: $charcoal-grey;
        }

        &.disabled .page-link {
            opacity: .3;
        }
    }
}

.last-updated {
    margin: 0 8px 0 0;
    font-size: 14px;
}

.btn-refresh {
    background: rgba($very-light-pink, .3);
    border-radius: 50%;
    display: block;
    border: none;
    cursor: pointer;
    transition: opacity .3s ease;
    color: $charcoal-grey;
    height: 40px;
    width: 40px;
    min-width: 40px;

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        opacity: .8;
    }

    &[disabled] {
        pointer-events: none;
        opacity: .5;
    }

    @include xs() {
        padding: 1px 6px;
    }
}

.total-count {
    display: flex;
    align-items: center;
    margin: 0 16px 0 0;
    font-size: 14px;

    .label::after {
        content: ':';
        padding-right: 5px;
    }
}
