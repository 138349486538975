@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";


.customer-finder {
    height: 100%;

    .search-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px;

        .search-for-caption {
            margin: 0 5px 0 0;
        }

        .form-check-input {
            margin: 0;
            position: static;
            display: none;
        }

        .search-type-text {
            border-radius: 15px;
            border: 1px solid #ccc;
            cursor: pointer;
            padding: 3px 10px;
            transition: border-color .3s ease-in;
        }

        .search-type {
            margin: 0 5px 0 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .form-check-input:checked + .search-type-text {
                border-color: $primary-light;
            }
        }
    }

    .search-field {
        min-width: 400px;
    }
    @include md() {
        .search-field {
            min-width: unset;
        }
    }

    .list-view {
        height: calc(100% - 104px);

        .cell-plan_name {
            text-transform: capitalize;
        }
    }
}
