@import "../../scss/mixins/media-query";

.promotions {
    height: 100%;

    .main-content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: calc(100% - 100px);

        @include xs() {
            display: block;
        }
    }

    .promotion-list-table {
        height: 100%;
        width: 100%;
        transition: width .3s ease-out;
        transform-origin: left;
    }
}
