// Font Family
$global-font-family: sans-serif, PingFangTC-Regular, Microsoft JhengHei !default;
$global-font-family-bold: sans-serif, PingFangTC-Semibold, Microsoft JhengHei !default;
$global-font-family-medium: sans-serif, PingFangSC-Medium, Microsoft JhengHei !default;

// Font size
$desktop-font-size: 16px !default;
$mobile-font-size: 16px !default;

// Colors
$passive-light: #F9F9F9 !default;
$passive-dark: #B9BCBF !default;
$primary-light: #00D7FF !default;
$success: #00B946 !default;
$warning: #FBE75B !default;
$danger: #FF1923 !default;
$primary-text-color: #FFF !default;

$deep-sky-blue: #0275ff !default;
$bluish-grey: #7c8c8f !default;
$bright-turquoise: #06f3f9 !default;
$black: #000000 !default;
$charcoal-grey: #323237 !default;
$very-light-pink: #d7d7d7 !default;
$shamrock: #00b946 !default;
$strawberry: #ff1923 !default;
$white: #fff !default;
$charcoal: #4a4a4a !default;
$white-smoke: #F5F5F5 !default;
$smoke: #9b9b9b !default;
$very-light-pink-two: #f3f3f3 !default;
$dull-blue: #173e6f !default;
$selective-yellow: #ffb800 !default;
$kiwi-green: #8be561 !default;
$medium-blue: #2e72bc!default;
$gray98: #fafafa !default;
$aqua-blue: #00d3d8 !default;
$turquoise-blue: #00b0d0 !default;
$dark-indigo: #0b1f37 !default;
$yellow-orange: #ffb800 !default;
$orange-peel: #ffa52b !default;
$cinnabar: #f03732 !default;

// Font colors
$secondary-font-color: $passive-dark;
$alternative-text-light-color: #D7D7D7 !default;

// Header height
$main-desktop-header-height: 72px !default;
$main-mobile-header-height: 52px !default;
$main-header-bottom-margin: 20px !default;
$main-desktop-header-tot-height: $main-desktop-header-height + $main-header-bottom-margin;
$main-mobile-header-tot-height: $main-mobile-header-height + $main-header-bottom-margin;

// Common spacing
$view-desktop-side-padding: 40px !default;
$view-mobile-side-padding: 20px !default;

// Dashboard break point
$dashboard-hide-break: 1024px !default;


// TV Monitor
/* This variable is rely on the scale setting of the TV on the Wall X 2
 * It will transfrom the size between layout in Zeplin to TV
 * If the size on zeplin is 100px, then the size should be setted as 100/$screen-scale
 */
$tv-screen-scale: 3.0; /* {device-scale} * {2screen into 1} */
$tv-col-gap: 40px;
$minimal-font-size: 12; /* unit: px */
