@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

$gap: 2px;
$gray-92: #ebebeb !default;
$white-smoke: #f5f5f5 !default;

.allowance {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 24px;

    @include xs() {
        flex-direction: column;
    }

    .title {
        width: 100%;
        margin-bottom: 2px;
        padding: 12px 24px;
        background-color: $gray-92;
        line-height: 16px;
        font-size: 14px;
        font-weight: bold;
        color: $charcoal-grey;
        text-align: left;

        @include xs() {
            align-items: flex-start;
        }
    }
    .content {
        width: 100%;
        margin: 0 -#{$gap};

        @include xs() {
            margin: 0;
        }
    }
    .condition {
        display: flex;
        flex-direction: column;
        margin: 2px;
        text-align: left;
        padding: 24px;
        padding-bottom: 19px;
        background-color: $white-smoke;
        flex: 0 0 calc(33.28% - #{$gap});
        align-self: stretch;
        @include xs() {
            flex: 0;
        }
    }
    .caption {
        margin-bottom: 8px;
        color: $bluish-grey;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;

    }
    .text {
        margin-bottom: 0;
        color: $charcoal-grey;
        font-size: 24px;
        @include xs() {
            font-size: 18px;
        }
    }
}

.app{
    &[class*="en"] {
        .allowance {
            letter-spacing: -0.01rem;
        }
    }

}