@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.ticket-mission-map {
    height: 100%;
    width: 30%;

    @include md() {
        width: 100%;
    }
}
