@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.navigation-bar {
    border-bottom: solid 1px #f1f3f3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    padding: 16px 0;
    margin: -20px 0 10px 0;
    width: 100%;

    .flex-group {
        max-width: 100%;
        margin: 0;

        @include md() {
            flex-direction: row;
        }
    }

    .navigation-title {
        @include font-family-default;
        margin: 0;
        font-size: 32px;
        font-weight: 300;
        color: #323237;
        line-height: 1.25;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include md() {
            white-space: normal;
            font-size: 18px;
        }
    }

    .icon.fas {
        background-color: rgba($very-light-pink, .3);
        border-radius: 50%;
        cursor: pointer;
        font-size: 19px;
        border: none;
        padding: 0;
        margin: auto 16px auto 0;
        height: 40px;
        width: 40px;
        min-width: 40px;
        color: #000000;
    }

    &.sticky-bar {
        position: sticky;
        top: $main-desktop-header-height;
        background: $white;
        z-index: 10;

        @include xs() {
            top: $main-mobile-header-height;
        }
    }
}
