/*
Single Scooter Model
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.overall-tickets {
    display: flex;
    flex-direction: column;

    .chart {
        margin-top: 0;
        width: 100%;
        min-height: 300px;

        .barchart {

            position: initial;
            overflow-x: scroll;

            &.auto-resize {
                width: 100%;
                height: 100%;
            }

            .svg-container {
                margin: auto;
            }

            .bar-text {
                font-weight: 500;
            }
        }
        .js-plotly-plot .plotly .cursor-pointer {
            cursor: default;
        }
    }
    .list {
        background-color: $gray98;
        list-style-type: none;
        flex-wrap: wrap;
        padding: 32px 24px 36px;
        margin: 0 -24px -24px;
        text-align: left;
        font-size: 16px;
        position: relative;

        .list-row {
            align-items: center;
            flex-direction: column;
            padding-left: 40px;

            &:first-child {
                padding-left: 0;
            }

            &.header {
                align-items: start;
                margin-left: 0;
                margin-right: auto;
                &>span:first-child {
                    color: rgba($charcoal-grey,.5);
                }

                .caption {
                    &::after {
                        content: '';
                        left: 24px;
                        width: calc(100% - 48px);
                        border-bottom: 1px solid rgba($charcoal-grey,.1);
                        position: absolute;
                        height: 1px;
                        margin-top: 27px;
                    }
                    &:first-child {
                        &::after {
                            border: none;
                        }
                    }
                    &:last-child {
                        &::after {
                            border: none;
                        }
                    }
                }
            }

            &>span {
                padding-bottom: 8px;
            }
        }
        .caption {
            @include font-family-medium();
            font-weight: 500;

            &.ticket-0275ff {
                color: $deep-sky-blue;
            }
            &.ticket-00d3d8 {
                color: $aqua-blue;
            }
            &.ticket-00b0d0 {
                color: $turquoise-blue;
            }
            &.ticket-2e72bc {
                color: $medium-blue;
            }
            &.ticket-7c8c8f {
                color: $bluish-grey;
            }
        }
        .counts {
            @include font-family-medium();
            font-weight: 500;

            &.warning {
                color: $strawberry;
            }
        }
    }

}
