@import "../../../scss/setup/variable";

.customer-document {
    max-width: 800px;

    .tabs {
        text-align: left;
    }
    .tab {
        padding: 12px 24px;
        font-size: 14px;
        line-height: 20px;
        color: $passive-dark;
        margin: 0;

        &.isActive {
            background-color: $white-smoke;
            color: #737D82;
        }

    }
    .tab-box {
        padding: 24px;
        background-color: $white-smoke;
        width: 656px;
        height: 435px;
        line-height: 435px;
        box-sizing: content-box;
    }

    .livePhoto {
        transition: all .3s ease-in-out;
    }

    .link-image {
        width: 100%;
        height: 100%;

        img {
            max-width: 100%;
            max-height: 100%;
        }


    }
}
