@import './font-family';

@mixin dotdotdot() {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @content;
}

@mixin details-title() {
    @include font-family-default;
    line-height: 28px;
    font-weight: 400;
    margin: 0 8px 0 0;
}

