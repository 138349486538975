.claim-warning-modal {
    .modal-title {
        flex-wrap: wrap;
        height: auto;
    }
    .modal-box {
        max-width: 680px;
        min-width: auto;
    }
    .message {
        text-align: left;
        margin-bottom: 40px;
    }
}
