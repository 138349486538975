@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.scooter-profile {
    .info-wrapper {
        display: flex;
        flex-wrap: wrap;
        font-size: 1rem;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin: 0 0 10px 0;
        position: relative;

        .card {
            width: calc(60% - 10px);
            margin: 0;
            height: 100%;
            padding: 10px;

            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @include md() {
            width: 100%;
            flex-direction: column;
            .card {
                margin: 0 0 10px 0;
                width: 100%;
            }
        }
    }

    .section-caption {
        margin: 0 0 10px 0;
    }

    .button-group {
        width: 100%;
    }

    & > .button-group {
        padding: 10px;
        background: rgba(255, 255, 255, .7);
        margin: 0 0 10px 0;
        text-align: left;
        justify-content: start;

        @include md() {
            flex-wrap: wrap;
        }

        button {
            @include sm() {
                margin-bottom: 8px;
            }
        }
    }

    .related-tickets {
        width: 30%;
        padding: 10px;
        text-align: right;
        flex-grow: 1;
        @include md() {
            width: 100%;
        }
        & > .title {
            &::after {
                content: ':';
                padding-right: 20px;
            }
        }
        & > a {
            display: inline-block;

            &:not(:last-child) {
                &::after {
                    content: ',';
                    padding-right: 10px;
                }
            }
        }
    }

    .profile-line {
        display: flex;
        margin: 0 0 1rem 0;

        .profile-caption {
            text-align: right;
            margin: 0 5px 0 0;
            flex: 1;
        }

        .profile-item {
            flex: 1;
            display: flex;
            text-align: left;
            word-break: break-all;
            align-items: center;

            &:empty::before {
                content: "--";
            }
        }
    }

    .last-updated {
        width: 100%;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .profile-caption {
            margin: 0;
        }
    }

    .plate-no {
        width: 100%;
    }

    .scooter-location {
        width: 40%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;


        @include md() {
            width: 100%;
            height: 25vh;
            position: relative;
        }
    }
    .warning-panel {
        max-width: 520px;
        margin-right: 12px;
    }
    .error {
        color: $strawberry;
    }
}
