@import "../../scss/mixins/media-query";

.assets {
    height: 100%;

    .group-selection {
        flex-direction: column;
    }
    .main-content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: calc(100% - 100px);

        @include xs() {
            display: block;
        }
    }

    .asset-list-table {
        height: 100%;
        width: 100%;
        transition: width .3s ease-out;
        transform-origin: left;
    }

    .asset-import-modal {
        .modal-box {
            .modal-title {
                z-index: 1;
            }
        }
        .download-section {
            margin: 0 0 16px 0;
        }

    }
}
