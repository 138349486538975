@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";
@import "../../scss/setup/variable";

.ticket-assignment-map {
    height: 100%;

    .assignment-container {
        height: calc(100% - 100px);

        .assignment-list {
            padding: 0 16px 0 0;
            margin-bottom: 0;
            flex-basis: 400px;
            max-width: 40%;
            overflow: auto;

            @include xs() {
                max-width: 100%;
            }

            .assignee {
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 13px;
                font-weight: normal;
                text-align: initial;
                cursor: pointer;

                .assignee-name {
                    margin-right: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .count {
                    @include font-family-bold();
                    margin-left: auto;
                    background-color: $deep-sky-blue;
                    color: #fff;
                    height: 20px;
                    line-height: 14px;
                    padding: 3px 8px;
                    border-radius: 10px;
                }
            }
        }

        .main-content-container {
            flex: 1;
        }
    }


    .ticket-mission-map {
        width: 100%;
        .toolbox {
            &.top-left-above {
                @include lg() {
                    top: 122px!important;
                    left: 13px!important;
                }
            }
            &.vip-layer {
                @include lg() {
                    top: 208px!important;
                    left: 13px!important;
                }
            }
        }
    }

    .btn-batch-edit,
    .amount,
    .selected-amount {
        margin: 0 8px 0 0;
    }

    .selection {
        .title {
            margin: 0 10px 0 0;
            font-size: 1rem;

            &::after {
                content: ":";
            }
        }
    }
}
