@import "../../scss/mixins/font-family";
@import "../../scss/mixins/media-query";

.mission-form {
    .navigation-bar {
        .btn-refresh {
            margin-right: 8px;
        }
    }

    .mission-detail {
        flex-direction: column;

        .control-group {
            .caption {
                @include font-family-bold;
                width: 25%;
                transform: translateY(0);
                text-align: left;

                @include xs() {
                    width: 35%;
                }

                &:not(:empty):after {
                    content: initial;
                }

                &.required:after {
                    content: '*';
                }
            }

            .small-input{
                width: calc(60% - 100px);
                min-width: 170px;
            }
        }

    }

    .title-section {
        min-height: 40px;
        margin: 6px 0 16px 0;

        .details-title {
            @include font-family-default;
            line-height: 28px;
            font-weight: 300;
            margin: 0 8px 0 0;
        }
    }
}