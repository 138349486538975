/*
Dots Overall Service Level
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";
@import "../../../scss/mixins/media-query";

.dots-detail {
    .list {
        flex-grow: 1;
        flex-wrap: wrap;
        list-style-type: none;
        padding: 0 24px 36px;
        text-align: left;
        font-size: 16px;
        position: relative;
        margin: 0 -24px -24px;
        cursor: pointer;

        @media (min-width: $screen-lg) {
            max-width: 1000px;
            margin-right: auto;
            margin-left: auto;
        }

        .list-row {
            align-items: center;
            flex-direction: column;
            padding-left: 40px;

            &:first-child {
                padding-left: 0;
            }

            &.header {
                align-items: start;
                margin-left: 0;
                margin-right: auto;
                &>span:first-child {
                    color: rgba($charcoal-grey,.5);
                }

                .caption {
                    .clickable-area {
                        left: 24px;
                        width: calc(100% - 48px);
                        border-bottom: 1px solid rgba($charcoal-grey,.1);
                        position: absolute;
                        height: 32px;
                        padding: 0 24px 0;
                        z-index: -1;



                    }
                    &.selected {
                        .clickable-area {
                            background-color: rgba($bright-turquoise, .1);
                        }
                    }
                    &:hover {
                        .clickable-area {
                            background-color: rgba($bright-turquoise, .1);

                        }
                    }
                    &:first-child {
                        .clickable-area {
                            border: none;
                        }
                        &:hover {
                            .clickable-area {
                                background-color: transparent;

                            }
                        }
                    }
                    &:last-child {
                        .clickable-area {
                            border: none;
                        }
                    }
                }
            }

            &>span {
                line-height: 32px;
                z-index: 0;
            }
        }
        .caption {
            @include font-family-medium();
            font-weight: 500;

            &.ticket-0275ff {
                color: $deep-sky-blue;
            }
            &.ticket-00d3d8 {
                color: $aqua-blue;
            }
            &.ticket-00b0d0 {
                color: $turquoise-blue;
            }
            &.ticket-2e72bc {
                color: $medium-blue;
            }
            &.ticket-7c8c8f {
                color: $bluish-grey;
            }
        }
        .counts {
            @include font-family-medium();
            font-weight: 500;

            &.warning {
                color: $strawberry;
            }
        }
    }


}
