/*
Single Bar Component
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.single-bar {
    justify-content: space-between;
    align-items: baseline;

    .single-bar-item {
        flex-direction: column;
        padding: 0 12px;
        flex-grow: 1;

        &:last-child {
            .name,
            .value {
                border-right: none;
                padding-right: 0;
            }
            .text {
                border-right: none;
            }
        }
    }
    .bar {
        margin: 0 auto 11px;
    }
    .text {
        flex-direction: column;
        padding-right: 12px;
        margin-right: -12px;
        width: calc(100% + 12px);
    }
    .name {
        width: 100%;
        @include font-family-medium();
        font-weight: 500;
        color: rgba($charcoal-grey, .5);
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 8px;

        &.name-0275ff {
            color: $deep-sky-blue;
        }
        &.name-00d3d8 {
            color: $aqua-blue;
        }
        &.name-00b0d0 {
            color: $turquoise-blue;
        }
        &.name-2e72bc {
            color: $medium-blue;
        }
        &.name-7c8c8f {
            color: $bluish-grey;
        }
    }
    .value {
        width: 100%;
        @include font-family-medium();
        font-size: 16px;
        line-height: 19px;
    }
}
