/*
Single Service Level
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.tv-overall-SL {
    display: flex;
    box-shadow: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;

    .info {
        min-width: 135px;
        flex-grow: 1;
        padding-right: 120px/$tv-screen-scale;


        @media (max-width: 1440px) {
            width: calc(100% - 180px - 52px);
        }

        .title {
            margin-bottom: 59px/$tv-screen-scale;
            color: $white;
            font-size: 78px/$tv-screen-scale;
        }

        .dash-info {
            border-bottom: 3px/$tv-screen-scale solid rgba($white, 0.1);
            padding: 0;
            height: 93px/$tv-screen-scale;

            &:last-child {
                border-bottom: none;
            }

        .dash-title {
            color: $white;
            font-size: 48px/$tv-screen-scale;
            line-height: 93px/$tv-screen-scale;
        }
        .dash-value {
            color: $white;
            font-size: 60px/$tv-screen-scale;
            line-height: 93px/$tv-screen-scale;
            &.warning {
                color: $yellow-orange;
            }
        }
        }
    }
    .chart {
        min-width: 180px;
        // margin: -24px;
        margin: -120px/$tv-screen-scale -120px/$tv-screen-scale 0 0;
        text-align: center;
        background-color: $dull-blue;
        display: flex;
        flex-direction: column;

        .gauge {
            margin-bottom: 33px/$tv-screen-scale;
            max-height: 390px/$tv-screen-scale;
            padding: 0 75px/$tv-screen-scale 0 75px/$tv-screen-scale;
        }

        .title {
            color: $white;
            @include font-family-medium();
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            line-height: 19px;

            &.warning {
                color: $selective-yellow;
            }
        }

        .service-level-pie {
            padding: 120px/$tv-screen-scale 0 80px/$tv-screen-scale 0;
            margin: 0 93px/$tv-screen-scale 0 93px/$tv-screen-scale;
            border-bottom: 3px/$tv-screen-scale solid rgba($white, 0.1);

        }

        .time-counter {
            padding: 80px/$tv-screen-scale 0 139px/$tv-screen-scale 0 ;
            text-align: center;
            color: $white;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;

            .clock {
                @include font-family-bold();
                font-size: 84px/$tv-screen-scale;
                line-height: 98px/$tv-screen-scale;
                margin-bottom: 16px/$tv-screen-scale;
            }
            .date {
                @include font-family-medium();
                font-size: 48px/$tv-screen-scale;
                line-height: 57px/$tv-screen-scale;
            }
            .period {
                margin-left: 30px/$tv-screen-scale;
            }
        }


    }
}
