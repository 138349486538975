@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

$side-spacing: 8px !default;
$major-width: calc(70% - #{$side-spacing}) !default;
$minor-width: 30% !default;

.ticket-list {
    height: 100%;

    .filters {
        text-align: left;

        @include md() {
            .custom-filter {
                margin: 0 $side-spacing $side-spacing 0;
            }
        }
    }
    .tot-selection {
        padding-right: 16px;
    }

    .main-content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: calc(100% - 100px);

        @include xs() {
            display: block;
        }
    }

    .ticket-list-table {
        height: 100%;
        width: 100%;
        transition: width .3s ease-out;
        transform-origin: left;

        &.with-map {
            width: $major-width;

            & + .ticket-mission-map {
                width: $minor-width;
            }

            @include md() {
                width: 100%;
            }
        }

        & + .ticket-mission-map {
            transition: width .3s ease-out;
            transform-origin: left;
            width: 0;
        }

        @include md() {
            width: 100%;
        }
    }

    .toggle-map {
        margin: 0 $side-spacing 0 0;

        .toggle-text {
            margin: 0 $side-spacing 0 0;
        }
    }
}
