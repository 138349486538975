@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.gtu-replacement {
    .modal-title {
        z-index: 1;
    }
    .modal-box {
        width: 800px;

        @include sm() {
            max-width: 100%;
            width: 100%;
        }
    }

    h3 {
        font-size: 20px;
        line-height: 28px;
        color: $charcoal;
        padding: 0 0 10px 0;
        margin-bottom: 0;
    }
    .collapse-banner {
        &.gtu-info {
            .card {
                padding: 20px 24px;
                width: 100%;
            }
            .card-body {
                padding: 0;
            }
            .gtu-info-group {
                margin-bottom: 18px;
                &>.row {
                    margin-bottom: 0;
                }
                .field {
                    @include font-family-bold;
                }
            }
            .data {
                display: flex;
                align-items: flex-end;
                word-break: keep-all;
                font-size: 14px;
                align-self: center;
                white-space: pre-wrap; // must use for multiple space in vertification date
            }
        }

        &.gtu-new-info {
            .card {
                padding: 20px 24px;
                width: 100%;
            }
            .card-body {
                padding: 0;
            }
        }
    }

    .form {
        .imei-search {
            display: flex;

            @include sm() {
                flex-direction: column;
            }

            .control-group {
                width: fit-content;

                @include sm() {
                    flex-direction: column;
                    width: 100%;
                }
            }

            .btn-go-search {
                margin-left: 12px;
                margin-top: 6px;
                @include sm() {
                    margin-left: 0;
                    margin-bottom: 24px;
                }
            }
        }

        .control-wrapper {
            width: 320px;
            @include sm() {
                width: 100%;
            }
        }
        .control-group {
            display: flex;
            flex-direction: row;
            padding: 6px 0;
            margin: 0;

            @include sm() {
                width: 100%;
                flex-direction: column;
            }

            .caption {
                padding-top: calc(.375rem + 1px);
                padding-bottom: calc(.375rem + 1px);
                display: flex;
                flex: 0 0 25%;
                max-width: 25%;
                min-width: 164px;

                &.required::after {
                    content: "*";
                }
            }
        }

        .button-group {
            margin-top: 36px;
        }

    }

    &.no-gtu {
        .modal-title {
            color: $strawberry;
        }
    }
}
