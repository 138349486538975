/*
Single Service Level
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.overall-SL {
    display: flex;

    .info {
        min-width: 135px;
        flex-grow: 1;
        padding-right: 52px;

        @media (max-width: 1440px) {
            width: calc(100% - 180px - 52px);
        }

        .title {
            margin-bottom: 23px;
        }

        .dash-info:last-child {
            padding-bottom: 0;
        }
    }
    .chart {
        min-width: 180px;
        margin: -24px;
        text-align: center;
        background-color: $gray98;
        display: flex;
        flex-direction: column;

        .gauge {
            margin-bottom: 8px;
        }

        .title {
            color: $deep-sky-blue;
            @include font-family-medium();
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            line-height: 19px;

            &.warning {
                color: $selective-yellow;
            }
        }

        .service-level {
            border-bottom: 1px solid rgba($charcoal-grey, 0.1);
            padding: 28px 24px 16px 26px;

        }

        .total-scooters {
            padding: 18px 0 0 0 ;
            text-align: center;
            color: $deep-sky-blue;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;

            .total {
                @include font-family-bold();
                font-size: 28px;
                line-height: 33px;
                margin-bottom: 4px;
            }
        }


    }
}
