@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.firmware-version {
    .error {
        color: $strawberry;
    }

    .modal-box {
        width: 800px;

        @include sm() {
            max-width: 100%;
            width: 100%;
        }
    }
    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        width: 100%;
    }

    .hide {
        display: none;
    }
    .firmware-version-info {
        margin-top: 8px;
        .collapse {
            .card {
                padding: 20px 24px;
                width: 100%;
            }
            .card-body {
                padding: 0;
            }
        }


        .firmware-info-group {
            margin-bottom: 18px;
            &>.row {
                margin-bottom: 0;
            }
            .field {
                @include font-family-bold;
            }
        }
        .data {
            display: flex;
            align-items: flex-end;
            word-break: break-all;
            font-size: 14px;
            align-self: center;
            white-space: pre-wrap;
            padding-top: calc(.375rem + 1px);
            padding-bottom: calc(.375rem + 1px);
        }
    }
}
