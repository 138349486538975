@import "../../scss/setup/variable";

.datetime-picker {
    position: relative;

    .btn-reset {
        position: absolute;
        font-size: 1.2rem;
        top: calc(19px - 0.6rem);
        right: 5px;
        border: none;
        background: transparent;
    }

    .invalid-feedback:empty {
        margin: 0;
    }
}
