@import "../../scss/setup/variable";
@import "../../scss//mixins//font-family";
@import "../../scss/mixins/media-query";

.ad-list {
    height: 100%;
    width: 100%;

    .list-view-wrap {
        height: calc(100% - 100px);
        width: 100%;
    }

    .search-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .search-for-caption {
            margin: 0 5px 0 0;
        }

        .search-field {
            min-width: 400px;
            margin: 0 10px 0 0;
        }

        @include md() {
            .search-field {
                min-width: unset;
            }
        }
    }

    .filters {
        text-align: left;

        .custom-filter {
            margin: 0 10px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    .pagination-status {
        margin: 0;
        width: calc(100% - 150px);

        ul {
            margin-bottom: 0;
        }

        .last-updated {
            margin: auto 10px auto auto;
        }
    }
}

.ad-form {
    .functional-group {
        .btn {
            margin: 0 4px 0 0;
        }
    }

    .form-container {
        text-align: left;
    }

    .value-column {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    .control-group {
        width: 100%;

        &.view-mode {
            margin-bottom: 0;
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    .radio-form-group {
        margin-bottom: 32px;
    }

    .card {
        margin: 0 0 10px 0;
        border: 1px solid #eee;
    }

    span.badge {
        margin: auto 0;
    }

    h2.caption {
        @include font-family-default();
        font-size: 24px;
        margin: 10px 8px 10px 0;
    }

    .publish-info {
        text-align: left;
        margin: 0 0 10px 0;

        .caption {
            font-weight: bold;
            margin: 0 5px 0 0;

            &::after {
                content: ":";
            }
        }

        &:last-child {
            margin: 0;
        }
    }

    .field {
        text-align: left;
        display: block;

        &.required::after {
            content: "*";
        }
    }

    .thumb {
        max-width: 100%;
        &.hide {
            display: none;
        }
    }

    form {
        .form-check {
            padding-left: 0;
            margin: 0;
        }

        .form-check-label {
            display: inline-block;
        }

        input[type="radio"],
        input[type="checkbox"] {
            vertical-align: middle;
        }
    }

    &.view-only form {
        .form-check-label,
        input {
            pointer-events: none;
        }

        input[type="file"],
        input[type="url"],
        input[type="text"],
        input[type="number"] {
            color: #000;
            padding: 0;
            border: none;
        }

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            &:not(:checked) + span {
                display: none;
            }
        }

        .file-uploader {
            display: none;
        }

        .with-unit,
        .form-check {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;

            input {
                width: auto;
            }
        }

        .display-type {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            text-align: left;
            margin: 0;
            padding: 0;
            height: 100%;
        }

        .file-input {
            position: relative;
            overflow: hidden;
            input[type='file'] {
                position: absolute;
                color: transparent;
                border: none;
                line-height: calc(2.25rem - 3px);
            }
            input[type='text'] {
                padding-left: 113px;
                color: transparent;
                text-shadow: 0 0 0 #B9BCBF;
                text-overflow: ellipsis;
                &:focus {
                    text-shadow: 0 0 0 #495057;
                    outline: none;
                }
            }
        }
    }
}
