@import "../../scss/mixins/util";

.rental-log-list {
    height: 100%;

    .search-for-caption {
        margin: 0 8px 0 0;
    }

    .divider {
        padding: 0 5px;
    }

    .record-periods {
        &::after {
            content: ":";
            margin: 0 3px 0 0;
        }
    }

    .export-button {
        margin-left: auto;
    }

    .cell-content:empty::before {
        content: "--";
    }

    .list-view {
        height: calc(100% - 104px);
    }

    .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
