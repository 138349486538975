@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.new-ticket {
    .modal-box {
        max-width: 640px;

        @include sm() {
            max-width: 100%;
            width: 100%;
        }

        .modal-title {
            z-index: 1;
        }
    }

    .conditional-result {
        .field {
            text-align: right;
        }
        .data {
            align-self: center;
            text-align: left;
        }
    }
}
