@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";


.system-coupon-list {
    height: 100%;
    width: 100%;

    .list-view-wrap {
        height: calc(100% - 100px);
        width: 100%;
    }

    .list-view:empty::before {
        content: "No Results";
        font-size: 3rem;
    }

    .divider {
        padding: 0 5px;
    }

    .record-periods {
        &::after {
            content: ":";
            margin: 0 3px 0 0;
        }
    }

    .search-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .search-for-caption {
            margin: 0 5px 0 0;
        }

        .search-field {
            min-width: 400px;
            margin: 0 10px 0 0;
        }

        @include md() {
            .search-field {
                min-width: unset;
            }
        }
    }

    .filters {
        text-align: left;

        .custom-filter {
            margin: 0 10px 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }

    .pagination-status {
        margin: 0;
        width: calc(100% - 170px);

        ul {
            margin-bottom: 0;
        }

        .last-updated {
            margin: auto 10px auto auto;
        }
    }

    .discount-value {
        padding: 0 5px 0 0;
    }

    .cell-title a,
    .cell-coupon_code .coupon-code-wrapper {
        @include dotdotdot();
    }
}

.system-coupon-form {
    .form-container {
        text-align: left;
    }

    .functional-group {
        .btn {
            margin: 0 8px 0 0;
        }
    }

    .card {
        margin: 0 0 40px 0;
    }

    .badge {
        font-size: 14px;
        margin-left: 8px;
    }

    .publish-info {
        text-align: left;
        margin: 0 0 10px 0;

        .caption {
            font-weight: bold;
            margin: 0 5px 0 0;

            &::after {
                content: ":";
            }
        }

        &:last-child {
            margin: 0;
        }
    }



    .caption {
        text-align: left;
    }

    .caption-detail {
        font-size: 24px;
        font-weight: 300;
        color: #4a4a4a;
        text-align: left;
    }

    .promo-text {
        margin: 0 30px 0 20px;
    }


    .coupon-status {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.71;
        color: $bluish-grey;

        &.error-coupon-status {
            color: $danger;
        }
    }

    .account-type {
        display: inline-block;
        text-transform: capitalize;
    }

    .field {
        text-align: left;
        display: block;

        &.required::after {
            content: "*";
        }
    }

    form {
        .form-check {
            padding-left: 0;
            margin: 0;
        }

        .form-check-label {
            display: inline-block;
        }

        input[type="radio"],
        input[type="checkbox"] {
            vertical-align: middle;
        }
    }

    .total-section {
        background: #f5dede;

        .row {
            margin: 0;
        }

        .col-sm-3 {
            display: flex;
            align-items: center;
        }
    }

    .total {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
            margin: 0 5px 0 0;
        }
    }

    .with-unit {
        display: flex;
        align-items: flex-end;
        word-break: keep-all;

        input {
            margin: 0 10px 0 0;
        }
    }

    .fas {
        &.fa-times {
            color: #f00;
        }

        &.fa-check {
            color: #0f0;
        }
    }

    .emphasis {
        font-weight: bold;
    }

    .redeem-time,
    .valid-time {
        width: 50%;
        height: 10px;
        background: #f5dede;
        margin: 0 auto 20px auto;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        position: relative;

        .from,
        .to {
            font-size: 11px;
            position: absolute;
        }

        .from {
            left: -30px;
        }

        .to {
            right: -30px;
        }
    }

    &.view-only form {
        .form-check-label,
        input {
            pointer-events: none;
        }

        input[type="text"],
        input[type="number"] {
            color: #000;
            padding: 0;
            border: none;
        }

        input[type="checkbox"],
        input[type="radio"] {
            display: none;

            &:not(:checked) + span {
                display: none;
            }
        }

        .with-unit,
        .form-check {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;

            input {
                width: auto;
            }
        }

        .display-type {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            text-align: left;
            margin: 0;
            padding: 0;
            height: 100%;
        }

        .type-badge {
            display: inline-block;
            border-radius: 10px;
            background: #eee;
            padding: 5px 10px;
            margin: 0 10px 0 0;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
}
