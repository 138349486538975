@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";

.segment-ctrl {
    padding: 0;
    list-style-type: none;
    cursor: pointer;
    background-color: rgba($very-light-pink, .3);
    border-radius: 20px;

    .item {
        font-size: 16px;
        line-height: 16px;
        padding: 12px 18px;
        border-radius: 20px;

        &.active {
            padding: 10px 16px;
            color: #fff;
            background-color: $deep-sky-blue;
        }
    }

}
