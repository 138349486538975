@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";
@import "../../scss/mixins/util";

.orderable-list-view {
    height: 100%;
    width: 100%;
    // overflow: auto;

    .table {
        margin-bottom: 0;
    }

    .cell-content {
        @include dotdotdot();
        white-space: pre-wrap;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
    }

    .header {
        display: flex;
        background-color: rgba(#d7d7d7, .5);
        border-bottom: 0;
        color: var(--charcoal-grey);
        font-size: 1rem;
        font-weight: bold;
        font-stretch: condensed;

        .cell {
            @include font-family-medium();
            border-right: 2px solid #fff;
            font-weight: 500;
            background-image: none;
            padding: 0 12px;

            &:last-child {
                padding: 0 12px;
                background: none;
            }
        }
    }

    .list-row {
        position: absolute;
        display: flex;
        border-bottom: 1px solid #eeeeee;
        transition: background-color 1s;

        &.row-odd {
            background-color: rgba(#d7d7d7, .05);
        }

        &.row-even {
            background-color: rgba($alternative-text-light-color, .25);
        }

        &:hover {
            cursor: grab;
        }
    }

    .cell {
        border: 0;
        border-right: 2px solid #fff;
        display: flex;
        line-height: 1.14;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 40px;
        padding: 0 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:empty::after {
            content: '--';
        }

        &:last-child {
            border-right: 0;
            padding: 0 36px 0 12px;
            background: url('../../assets/align-justify.svg') no-repeat 99% center;
        }
    }
}
