@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";
@import "../../scss/mixins/media-query";

.dash-info {
    padding: 4px 0px;
    line-height: normal;
    border-bottom: 1px solid rgba($charcoal-grey, .1);
    margin: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
        border-bottom: none;
    }

    .dash-title {
        @include font-family-medium();
        font-size: 16px;
        font-weight: 500;
        color: $dull-blue;
        line-height: 20px;
    }
    .dash-value {
        @include font-family-bold();
        font-size: 20px;
        color: $dull-blue;
        line-height: 23px;
        &.warning {
            color: $yellow-orange;
        }
    }

}
