@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.scooter-map {
    height: 100%;

    .spinner-wrapper {
        height: calc(100% - 54px);
    }

    .map-view {
        height: calc(100% - 104px);

        .toolbox {
            &.top-left-above {
                @include lg() {
                    top: 122px!important;
                    left: 13px!important;
                }
            }
            &.vip-layer {
                @include lg() {
                    top: 208px!important;
                    left: 13px!important;
                }
            }
        }
    }
}
