@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";

.customer-list {
    height: 100%;
    width: 100%;

    .list-view {
        height: calc(100% - 98px);
    }

    .functional-group {
        text-align: left;

        .btn {
            margin: 0 8px 0 0;
        }
    }

    .search-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .search-for-caption {
            margin: 0 5px 0 0;
        }

        .search-type-text {
            border-radius: 15px;
            border: 1px solid #ccc;
            cursor: pointer;
            padding: 3px 10px;
            transition: border-color .3s ease-in;
        }

        .search-type {
            margin: 0 5px 0 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .form-check-input:checked + .search-type-text {
                border-color: $primary-light;
            }
        }
    }

    .search-field {
        min-width: 400px;
        margin: 0 10px 0 0;
    }

    @include md() {
        .search-field {
            min-width: unset;
        }
    }

    .filters {
        text-align: left;

        .custom-filter {
            margin: 0 10px 0 0;
            .filter-option span{
                text-transform: capitalize;
            }
            &:last-child {
                margin: 0;
            }
        }
    }

    .pagination-control-sec {
        .page-size-select {
            min-width: 90px;
        }

        .last-updated {
            margin: auto 10px auto auto;
        }
    }

    .list-view {
        .cell-plan_name {
            text-transform: capitalize;
        }
    }
}

.customer-form {
    .btn-unlock {
        padding: 0 2px;
        path{
            stroke: $deep-sky-blue;
        }
        &:disabled {
            path {
                stroke: $very-light-pink;
            }
        }
    }
    .functional-group {
        .btn {
            margin: 0 8px 0 0;
        }
    }

    .customer-rental-history {
        .error-message {
            display: none;
        }
    }

    .compensation-modal {
        .center {
            margin: 0 auto;
        }

        .modal-box {
            overflow: visible;
        }
    }
    .view-documents-modal {

        .function-button {
            text-align: right;
            margin: 40px 0 0;
        }
    }

    .form-container {
        text-align: left;
        margin-bottom: 10px;

        .form-check {
            padding: 0px;
            .form-check-label {
                display: inline-block;
                span {
                    margin-right: 20px;
                }
            }
        }
    }

    .cards {
        border: 1px solid #eee;
        border-radius: 4px;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        margin: 0;

        .form-group {
            margin-bottom: 10px;

            .col-sm-9 {
                display: flex;
                align-items: center;
            }
        }

        h3 {
            margin-bottom: 20px;
        }

        .blocker {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);
        }
        .id-information-sec {
            display: flex;
            flex-direction: column;
            align-self: start;
            width: 100%;
            margin: 0;
            padding: 0;
            .card:first-child {
                min-height: 0;
                width: 100%;
                height: 100%

            }
            .card {
                margin: 0;
                border: none;

                &.customer-document-info {
                    height: auto;
                    margin-bottom: 42px;
                }
            }


        }

    }
    @include md() {
        .cards {
            display: unset;
        }
    }

    .field {
        text-align: left;
        display: block;

        &.required::after {
            content: "*";
        }
    }
    .field-plan {
        text-transform: capitalize;
    }

    input[type="checkbox"],
    input[type="radio"] {
        vertical-align: middle;
    }
    .field-new_id {
        text-transform: uppercase;
    }
    time.verified-input,
    span.verified-input {
        display: inline-block;
        margin-right: 10px;
        padding: calc(.375rem + 1px) 0;

        &:empty::before {
            font-family: inherit;
            content: "--";
        }
    }
    time.verified-input {
        margin-left: 10px;
        &::before {
            content: '(';
            display: inline-block;
        }
        &::after {
            content: ')';
            display: inline-block;
        }
    }

    .attributes {
        &:empty::before {
            font-family: serif;
            content: "--";
        }
    }

    .cell-title {
        a {
            @include dotdotdot();
        }
    }

    .customer-status-control {
        min-height: 66px;
        align-items: start;

        .control-wrapper {
            margin-top: 10px;
        }
    }
}
