@import "../../../scss/setup/variable";
@import "../../../scss/mixins/util";
@import "../../../scss/mixins/media-query";
@import "../../../scss/mixins/font-family";
@import "../../../scss/setup/variable";

.ticket-details {
    width: 70%;
    float: left;
    height: 90vh;
    margin-top: 50px;
    &.fix-height {
        @include xs () {
            height: 120vh;
        }
    }
    @include md() {
        width: 100%;
        float: unset;
        height: unset;
        margin-top: 20px;
    }

    .form {
        height: 100%;
    }

    .basic-info {
        flex-wrap: wrap;

        .control-group {
            width: calc(50% - 10px);
            @include xs() {
                width: 100%;
            }
            &.view-mode {
                align-items: center;
            }
            &:nth-of-type(2n) {
                &:not(.view-mode)+.control-group.view-mode {
                    margin-bottom: 35px;
                }
            }

        }

        .search-field {
            .control-wrapper {
                width: 100%;
            }
            .control-group {
                width: 100%;
            }
        }
    }

    .have-regular-maintain {
        .control-group:not(.view-mode) {
            .control-wrapper {
                margin-top: 10px;
            }
        }
    }

    .regular-maintain-info {
        flex-direction: column;
        align-items: flex-start;

        & .control-group:first-child {
            .inline-wrapper {
                display: flex;
                align-items: center;

                .form-control {
                    flex: 1 1 0;
                }
            }
        }
    }

    .rdtPicker {
        @include xs() {
            right: 0;
        }
    }

    .form-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        flex-wrap: wrap;

        .refresh-section {
            flex-basis: 100%;

            @include xs() {
                flex-direction: row;
            }
        }

        .editable-section {
            width: calc(100% - 2rem);

            @include md() {
                width: 100%;
            }

            .card {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    .log-line {
        margin: 0 0 4px 0;
    }

    .card {
        padding: 23px 24px;

        .card-body {
            padding: 0;
        }
    }

    .log, .comment {
        margin: 0 0 8px 0;
        font-size: 14px;
        word-wrap: break-word;
        white-space: pre-wrap;

        &:last-child {
            margin-bottom: 16px;
        }


        .notes {
            font-size: 16px;
            line-height: 1.75;
            color: #4a4a4a;
        }

        .comment-footer {
            @include font-family-medium();
        }

        .log-footer {
            @include font-family-bold();
        }

        .comment-footer, .log-footer {
            height: 28px;
            font-size: 14px;
            line-height: 2;
            color: $bluish-grey;

            @include xs() {
                display: block;
                height: auto;
                & > span {
                    display: block;
                }
            }
            .author {
                margin: 0 8px 0 0;
            }
        }

        .comment-footer {
            margin-top: 15px;
            margin-bottom: 0;
        }

        .log-footer {
            margin-top: 8px;
            margin-bottom: 20px;
        }
    }

    .description {
        padding-bottom: 2rem;
    }

    .comment {
        background-color: rgba($very-light-pink, .25);
        border-radius: 10px;
        padding: 16px;
    }

    .new-comment {
        .comment-container {
            position: relative;
        }

        .form-control {
            padding-bottom: 2rem;
        }

        .attachment-and-buttons {
            flex-direction: row;
            height: 60px;
        }

        .error-message {
            height: 0;
            line-height: 0;
        }

        .icon {
            background: none;
            border: 0;
            cursor: pointer;
            margin: 0;
            padding: 10px;
            font-size: 16px;
            color: inherit;

            &:disabled {
                background-color: transparent;
                border: 0;
            }
        }

        .paperclip {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $very-light-pink;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 8px 0 0;

            &.disabled {
                pointer-events: none;
                border: 2px solid rgba(215, 215, 215, .3);
                background-color: rgba(215, 215, 215, .3);
                color: $alternative-text-light-color;
            }

            .file-uploader {
                display: none;
            }
        }

    }
    .ticket-mission-map {
        height: 100%;
        width: 30%;

        @include md() {
            width: 100%;
        }
    }
}

.line-clamp {
    h2 {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        /* autoprefixer: off */
    }
}
