

@import "../../scss/mixins/util";
.additional-fee {
    height: 100%;
    width: 100%;


    .new-btn {
        margin-right: 10px;
    }

    .list-view-wrap {
        height: calc(100% - 100px);
        width: 100%;
    }

    .datetime-range-filter {
        .collasible-panel {
            &:not(.show) {
                display: none;
            }
            &.show {
                right: 0;
            }
        }
    }
}
