@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.regular-maintenance {
    height: 100%;

    .btn-new-ticket {
        margin: 0 8px 0 0;
    }

    .search-field {
        margin: 0 10px 0 0;

        @include sm() {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    .filters {
        display: flex;
        flex-wrap: wrap;

        @include sm() {
            justify-content: flex-start;
        }

        .custom-filter {
            margin: 0 5px 0 0;

            @include sm() {
                margin: 0 5px 5px 0;

                .btn-filter {
                    font-size: 14px;
                }
            }
        }
    }

    .pagination-control-sec {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        ul {
            margin-bottom: 0;
        }
        .tot-selection {
            margin: 0 10px 0 0;
        }
        @include md() {
            flex-wrap: wrap;
            .page-size-select {
                display: none;
            }
        }
    }
    .maintenance-list-control-sec {
        .list-view-control {
            margin-right: 10px;
        }
    }

    .maintenance-list-table {
        display: flex;
        flex-direction: row;
        height: calc(100% - 100px);

        @include md() {
            display: block;
            flex-direction: column;
            padding: 0;
        }

        .list-view-wrap {
            overflow: auto;
            width: 100%;
            transition: all .3s ease-out;

            @include md() {
                width: 100%;
                height: 100%;
                max-height: calc(100vh - #{$main-mobile-header-tot-height});
                overflow: scroll;
            }
        }

        .cell-checkbox {
            padding: 0;

            label {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                height: 100%;
                width: 38px;
            }

            input[type="checkbox"] {
                cursor: pointer;
            }
        }
        .text-criteria {
            color: $yellow-orange;
            font-weight: bold;
        }
    }

    .corporate-cell {
        position: relative;
        max-width: 100%;
        margin: auto;

        .corporate-cell-display {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
