@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";
.return-photo {

    .link {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
    }

    .preview-modal {
        .modal-box {
            @include md() {
                height: 100%;
            }
        }

        .modal-body {
            height: 484px;
            overflow: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .preview {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
