/*
Single Scooter Model
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.tv-overall-scooter-model {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .chart {
        text-align: left;
        min-width: 135px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .title {
            margin-top: 120px/$tv-screen-scale;
            margin-bottom: 66px/$tv-screen-scale;
            @include font-family-bold();
            font-size: 78px/$tv-screen-scale;
            color: $white;
        }

        .model-pie-chart {
            margin: auto;
            width: 100%;
            height: 100%;
            max-width: 240px;
            max-height: 240px;
        }

    }

    .model-list {
        width: min-content ;
        min-width: (726px/$tv-screen-scale) - 1px;
        display: inline-flex;
        margin-right: -117px/$tv-screen-scale;
        text-align: center;
        background-color: $dull-blue;
        color: $white;
        font-size: 16px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;

        $caption-width: 99px/$tv-screen-scale;
        $caption-padding-h: 39px/$tv-screen-scale;
        .caption {
            padding: 72px/$tv-screen-scale $caption-padding-h;
            @include font-family-bold();
            color: white;
            font-size: 14px;
            width: $caption-width;
            line-height: 72px/$tv-screen-scale;

        }
        .activation {
            width: calc(100% - #{$caption-width} - #{2*$caption-padding-h});
            flex-wrap: wrap;
            align-items: start;
            flex-direction: column;
            gap: 6px/$tv-screen-scale;
        }
        .content {
            flex-direction: row;
            @include font-family-medium();
            font-weight: 500;
            // padding-bottom: -1px+44px/$tv-screen-scale;
            gap: 12px;
            justify-content: start;


            ~ .content {
                // padding-left: 74px/$tv-screen-scale;
            }

            >span {
                line-height: 57px/$tv-screen-scale;
            }
        }

        .label-online,
        .label-offline {
            margin-bottom: 12px/$tv-screen-scale;
        }

        &>[class*="model-"] {
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 60px/$tv-screen-scale;
        }

        .model-edd90c {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: #f9e304;
            }
            .label-online {
                color: #edd90c;

            }
            .label-offline {
                color: #fdf076;
            }
        }

        .model-00d7ff {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $primary-light;
            }
            .label-online {
                color: $primary-light;

            }
            .label-offline {
                color: #80ebff;
            }

        }
        .model-ffa52b {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $orange-peel;
            }
            .label-online {
                color: $orange-peel;

            }
            .label-offline {
                color: #ffd295;
            }
        }
        .model-8be561 {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $kiwi-green;
            }
            .label-online {
                color: $kiwi-green;

            }
            .label-offline {
                color: #b4e39e;
            }
        }
        .model-f03732 {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $cinnabar;
            }
            .label-online {
                color: $cinnabar;

            }
            .label-offline {
                color: #f99b9a;
            }
        }
    }
}
