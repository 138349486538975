@import "../../../scss/setup/variable";

.toolbox {
    background: #ccc;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, .3);
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;

    &.top-right {
        top: 10px;
        right: 10px;
    }

    &.bottom-right {
        bottom: 20px;
        right: 10px;
    }

    &.top-left-above {
        top: 120px;
        left: 9px;
    }

    &.vip-layer {
        top: 206px;
        left: 9px;
        .vip-panel {
            position: absolute;
            padding: 16px;
            width: 230px;
            height: 265px;
            top: 50px;
            left: 0px;
            border-radius: 4px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
            background-color: #ffffff;
            text-align: left;
        }
    }

    &.vip-layer-top {
        top: 10px;
        right: 10px;
        transform: translateY(350%);
        .vip-panel {
            position: absolute;
            padding: 16px;
            width: 230px;
            max-height: 265px;
            top: 50px;
            right: 0px;
            border-radius: 4px;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
            background-color: #ffffff;
            text-align: left;
        }
    }

    .map-toolbox-btn {
        &.btn {
            border-radius: 0;
            color: #666;
            font-size: 1.2rem;
            padding: 0;
            line-height: 1;
            background: #FFF;
            margin: 0 0 1px 0;
            height: 40px;
            width: 40px;

            &:hover {
                opacity: 1;
            }
        }

        &:last-child.btn {
            margin: 0;
        }
    }

    .btn.btn-vms {
        background-image: url(../../../assets/map-gostation-switch.svg);
        background-repeat: no-repeat;
        background-position: center;

        &.enabled {
            background-image: url(../../../assets/gostation-switch-active.svg);
        }
    }

    .btn.btn-vip {
        background-image: url(../../../assets/icon-layers.svg);
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 0;

        &.enabled {
            background-image: url(../../../assets/icon-layers.svg);
        }
    }

    .vip-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.75;
        color: var(--deep-sky-blue);
    }
    hr {
        margin: 8px 0px;
    }
    .vip-item {
        display: grid;
        max-height: 180px;
        overflow-x: hidden;
        .filter-option{
            display: flex;
            align-items: center;
        }
        .vip-text {
            font-size: 14px;
            font-weight: normal;
            line-height: 2;
            text-overflow: ellipsis;
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            color: var(--charcoal-grey);
        }
    }
}
