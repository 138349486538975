@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

@media print
{
    body * { visibility: hidden; }
    .contentToPrint * {
        visibility: visible;
        font-size: 24px !important;
        h2 {
            font-size: 32px !important;
        }
        .linkToHide { display: none; }
    }
    .contentToPrint {
        position: absolute;
        top: 40px;
        width: 200%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-gap: 20px;
    }
    .app-container {
        padding: 0px !important;
    }
    .third-image {
        text-align: center;
        div {
            text-align: left;
            max-width: 90%;
            margin: 0px auto;
        }
        img {
            text-align: center;
        }
    }
}

.violation-bar {
    border-bottom: solid 1px #f1f3f3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    line-height: 1;
    padding: 16px 0;
    margin: -20px 0 10px 0;
    width: 100%;

    .violation-title {
        @include font-family-default;
        margin: 0;
        font-size: 32px;
        font-weight: 300;
        color: #323237;
        line-height: 1.25;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include md() {
            white-space: normal;
            font-size: 18px;
        }
    }
}

.violation-detail {
    .form-group-section {
        margin: 0;
    }

    .modal-box {
        overflow: visible;
    }

    .caption {
        font-size:16px;
    }

    .view-control {
        font-size:16px;
        &:empty::before {
            content: "--";
        }
    }

    .form-container {
        text-align: left;
    }
    .text {
        font-size: 32px;
    }
    .cards {
        border-radius: 4px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 6px;

        .card {
            border: 0;
        }

        .column {
            width: 100%;
        }

        .grid-template {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            grid-gap: 20px;
            .second-image {
                grid-column-start: 1;
            }
            img {
                max-width: 90%;
            }
        }
    }
}


