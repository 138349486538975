.period {
    .periods-title {
        &::after {
            content: ":";
            margin: 0 3px 0 0;
        }
    }
    .divider {
        padding: 0 5px;
    }
}
