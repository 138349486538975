/*
Single Scooter Model
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.overall-model {
    display: flex;

    .chart {
        min-width: 135px;
        padding-right: 50px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .title {
            margin-bottom: 23px;
        }

        .model-pie-chart {
            margin: auto;
            width: 100%;
            height: 100%;
            max-width: 270px;
            max-height: 270px;
        }

    }

    .model-list {
        width: 193px;
        margin: -23px -25px;
        text-align: center;
        background-color: $gray98;
        font-size: 16px;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: start;

        $caption-width: 34px;
        .caption {
            @include font-family-bold();
            color: white;
            font-size: 14px;
            width: $caption-width;
            line-height: 72px;
            height: 72px;
        }
        .activation {
            width: calc(100% - #{$caption-width});
            flex-wrap: wrap;
            align-items: center;
        }

        .content {
            flex-direction: row;
            width: 100%;
            @include font-family-medium();
            font-weight: 500;
            gap: 12px;
            height: 24px;
            justify-content: start;
        }

        .label-online,
        .label-offline {
            line-height: 19px;
            padding-left: 18px;
        }

        &>[class*="model-"] {
            flex-direction: row;
            align-items: center;
        }

        .model-edd90c {
            width: 100%;
            flex-wrap: wrap;

            .caption {
                background: #f9e304;
            }
            .label-online {
                color: #edd90c;

            }
            .label-offline {
                color: #fdf076;
            }
        }

        .model-00d7ff {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $primary-light;
            }
            .label-online {
                color: $primary-light;

            }
            .label-offline {
                color: #80ebff;
            }

        }
        .model-ffa52b {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $orange-peel;
            }
            .label-online {
                color: $orange-peel;

            }
            .label-offline {
                color: #ffd295;
            }
        }
        .model-8be561 {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $kiwi-green;
            }
            .label-online {
                color: $kiwi-green;

            }
            .label-offline {
                color: #b4e39e;
            }
        }
        .model-f03732 {
            width: 100%;
            flex-wrap: wrap;
            .caption {
                background: $cinnabar;
            }
            .label-online {
                color: $cinnabar;

            }
            .label-offline {
                color: #f99b9a;
            }
        }
    }
}
