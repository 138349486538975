
@import "../../scss/setup/variable";
@import "../../scss/mixins/util";

.upload-attachment {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;

    .upload-attachment-list {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        width: 100%;
        flex-wrap: wrap;
        gap: 8px;

        .upload-attachment-item {
            width: 100%;
            flex: 1 0 auto;
            display: flex;
            justify-content: flex-start;
            line-height: 36px;
        }
        .file-no {
            width: 25%;
            min-width: 25%;
            font-weight: 600;
            margin-right: 8px;
        }
        .file-wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 60%;
            flex: 1;
            &.left {
                justify-content: initial;
            }
        }
        .file-name {
            @include dotdotdot();
            cursor: pointer;
            color: $deep-sky-blue;
            text-decoration: underline;
        }
        .file-name--empty {
            color: inherit;
        }
        .btn-upload {
            margin-right: 16px;
        }
        .btn-delete {
            height: 36px;
            width: 36px;
            min-width: 36px;
            margin-left: auto;
            padding: 0;
            text-align: center;
            display: inline-block;
            background-color: transparent;
            img {
                display: inline-block;
                line-height: 36px;
                width: 20px;
                margin-top: -2px;
            }
        }
        .file-uploader {
            height: 36px;
            line-height: 36px;
            padding: 4px 16px;
            border: 2px solid $deep-sky-blue;
            color: $deep-sky-blue;
            cursor: pointer;
            margin-right: 16px;
            font-size: 14px;

            .file-input {
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 100%;
                display: none;
            }
        }
    }

    .upload-attachment-modal {
        .modal-box {
            max-width: 726px;
        }

        .modal-title {
            @include dotdotdot();
            padding-right: 40px;
            max-width: 100%;
            z-index: 2;
        }
        .modal-body {
            height: 484px;
            overflow: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;

        }
        .media {
            display: block;
            margin: 0 auto;
            max-height: 100%;
            max-width: 100%;
        }
    }

    .no-files {
        background-color: rgba($very-light-pink, .25);
        border-radius: 10px;
        padding: 16px;
        display: flex;
        width: 100%;
        color: $smoke;
        @include font-family-default();
        font-size: 14px;
        font-weight: 400;
        margin: 0;

    }
}
