@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";

.rental-management {
    height: 100%;
    width: 100%;

    .rental-status {
        @include dotdotdot();
    }

    .title {
        margin: auto;
    }

    .control-col {
        margin: 0;
    }

    .divider {
        padding: 0 5px;
    }

    .record-periods {
        &::after {
            content: ":";
            margin: 0 3px 0 0;
        }
    }

    .history-filter {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 10px 5px;
        margin: 0 0 50px 0;
        width: 100%;

        .form-row {
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        .form-group {
            margin: 0;
        }
    }

    .list-view-wrap {
        height: calc(100% - 100px);
        width: 100%;
    }

    .pagination-control-sec {
        margin: 5px 0;

        ul {
            margin-bottom: 0;
        }

        .last-updated {
            flex-grow: 1;
            text-align: right;
            margin: auto 5px;
        }
    }

    .reservation-col,
    .duration-col {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .reservation-time {
            width: 50%;
            min-width: 150px;
        }
        .cancel-time {
            color: $danger;
            width: 50%;
            min-width: 150px;

            &:not(:empty) {
                &::before {
                    content: "(";
                }

                &::after {
                    content: ")";
                }
            }
        }
        .start-time {
            width: calc( 50% - 5px );
            min-width: 150px;
        }
        .start-to-end {
            width: 6px;
            @include md() {
                display: none;
            }
        }
        .end-time {
            width: calc( 50% - 5px );
            min-width: 150px;
        }
        .icon {
            margin: auto 0;
        }
    }

    .inactive {
        height: 1px;
        overflow: hidden;
        opacity: 0;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        width: 100%;
    }

    .hide {
        display: none;
    }

    // override
    .form-control[readonly] {
        background-color: $primary-text-color;
    }
}
