.factor-auth-modal {
    &.hide {
        display: none;
    }
    .last-factor-group {
        width: 100%;
        flex-wrap: wrap;
        border-top: 1px solid #f1f3f3;
        padding-top: 24px;
    }
}
