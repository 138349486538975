@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";
@import "../../scss/mixins/media-query";

.dashboard {
    background: $very-light-pink-two;
    margin: -1*$main-header-bottom-margin -1*$view-desktop-side-padding -20px;
    padding: $main-header-bottom-margin 0 82px;
    overflow: auto;
    min-height: calc(100vh - #{$main-desktop-header-height});
    flex-direction: column;
    display: flex;

    .dashboard-smart {
        display: contents;
    }

    .dashboard-container {
        flex-wrap: wrap;
        flex-grow: 1;
        overflow: auto;
        height: fit-content;
        padding-top: 24px;

        @media (max-width: $dashboard-hide-break) {
            flex-direction: column;
        }
    }

    .navigation-bar {
        padding: 4px 0 0 0;
        margin: 0 $view-desktop-side-padding;
        justify-content: start;
        width: auto;

        .type-select {
            margin-left: 24px;

        }
        .refresh-area {
            margin-left: auto;
            .last-updated {
                display: inline-block;
            }
            .btn-refresh {
                display: inline-block;
            }
        }

        .icon {
            display: none;
        }
    }

    .dashboard-overall {
        flex-wrap: wrap;
        width: 100%;
        margin: 0 $view-desktop-side-padding;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;

        @media (max-width: $dashboard-hide-break) {
            display: none;
        }

        .right-column {
            width: calc(38% - 12px);
            flex-direction: column;

            @include lg() {
                width: 100%;
                flex-direction: row;
                align-items: flex-start;
            }
        }
    }

    .dashboard-dots {
        flex-wrap: wrap;
        width: 100%;
        margin: 0 $view-desktop-side-padding 82px;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 0;
        align-items: stretch;

        @media (max-width: $dashboard-hide-break) {
            display: none;
        }
    }

    .dashboard-empty {
        display: flex;
        width: calc(100% - 2 * #{$view-desktop-side-padding});
        border: 1px solid $very-light-pink;
        padding: 0 3.5px;
        min-height: inherit;
        flex-grow: 1;
        margin: 0 $view-desktop-side-padding;

        @media (min-width: #{$dashboard-hide-break + 1}) {
            display: none;
        }

        @include xs() {
            flex-direction: row;
        }

        .warning {
            width: 296px;
            @include font-family-medium();
            font-weight: 600;
            font-size: 20px;
            color: $bluish-grey;
            text-align: center;
            margin: 0 auto;

            @include xs() {
                width: 156px;
            }

            .point {
                @include font-family-bold();
                font-weight: bold;
            }

        }
    }



    .services-block {
        display: flex;
        flex-wrap: wrap;
        max-height: 212px;
        overflow: hidden;
        width: 100vw;
        margin: -12px -32px 0;

        .single-SL {
            width: calc(100% - 24px);

            @media (max-width: 1300px) {
                margin-left: 13px;
                margin-right: 13px;
            }

        }
        .slider {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 0 10px;

            .slick-list {
                padding: 0 16px;
                margin: 0 -34px;
                width: 100%;
                position: initial;
                height: 212px;
            }
            .slick-track {
                width: 100%;
                margin: 0 38px;
                position: relative;
                left: -53.5px;

                @media (max-width: 1300px) {
                    left: -4.3vw;
                }
                @include lg() {
                    left: -4.8vw;
                }
            }
            .slick-slide {
                width: calc(25vw - 14.1px);

                @media (max-width: 1300px) {
                    width: calc(33.3vw - 17.9px);
                }

                &>div {
                    border: none;
                }
            }
            .slick-arrow {
                z-index: 1;
            }
            .slick-prev {
                width: 44px;
                height: 44px;
                cursor: pointer;
                display: inline-block !important;
                border-radius: 100%;
                background: url(../../assets/form-dropdown-arrow-left.svg);
                background-repeat: no-repeat;
                background-position: 13px center;
                background-color: transparent;
                background-size: 13px 22px;

                &:hover {
                    background-color: #fff;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                }
            }
            .slick-next {
                width: 44px;
                height: 44px;
                cursor: pointer;
                display: inline-block !important;
                border-radius: 100%;
                background: url(../../assets/form-dropdown-arrow-right.svg);
                background-repeat: no-repeat;
                background-position: 17px center;
                background-color: transparent;
                background-size: 13px 22px;

                &:hover {
                    background-color: #fff;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                }
            }
            .slick-disabled {
                opacity: .5;
            }
        }
        .info-card {
            .title {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
    .overall-tickets {
        width: calc(62% - 12px);
        margin-left: 0;

        @include lg() {
            width: 100%;
            margin-right: 0;
        }
    }

    .overall-SL {
        width: 100%;

        @include lg() {
            margin-left: 0;
        }
    }
    .overall-model {
        width: 100%;

        @include lg() {
            margin-right: 0;
        }
        .chart {
            @media (max-width: 1350px) {
                margin: 0;
                padding-right: 0;
                max-width: calc(100% - 180px);
            }
        }
        .model-list {
            @media (max-width: 1350px) {
                width: 180px;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: start;
                align-content: stretch;
            }

            &>div {
                @media (max-width: 1350px) {
                    width: 100%;
                }
            }

            .content {
                @include lg() {
                    padding-bottom: 16px;
                }
            }

            .caption {
                @include lg() {
                    margin-bottom: 16px;
                }
            }
        }

    }

    .dots-overall-SL {
        width: calc(38% - 12px);
        flex-direction: column;
        margin-top: -12px;

        @include lg() {
            width: calc(100% + 24px);
            flex-direction: row;
            margin: -12px -12px 12px;
            align-items: start;

            .info-card {
                height: 236px;
            }
        }

        .info-card {
            width: 100%;
        }
        .tickets-status  {
            margin-bottom: 24px;
            flex-grow: 1;

            @include lg() {
                margin-bottom: 12px;
            }
        }
    }
    .dots-map {
        padding: 0;
        flex-direction: column;
        flex-grow: 1;
        max-width: calc(62% - 12px);
        margin: 0 0 24px 0;
        min-height: 438px;

        @media (min-width: 1400px) {
            max-height: 455px;
        }

        @include lg() {
            max-width: 100%;
            height: 438px;
        }

        .tooltip {
            @include font-family-medium();
            width: 277px;
            padding: 16px 24px;
            display: inline-block;
            position: absolute;
            left: 16px;
            bottom: 16px;
            background-color: #fff;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            opacity: 1;

            .name {
                @include font-family-bold();
                font-size: 20px;
                font-weight: 600;
                color: $dull-blue;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
    .dots-detail {
        margin: 0;
        width: 100%;
    }
}


