@import "../../../scss/mixins/font-family";
@import "../../../scss/mixins/util";

.new-additional-fee {
    .navigation-title {
        font-weight: 400;
    }
    .new-fee-form {
        display: flex;
        flex-wrap: wrap;
        padding: 6px 0;
        .error-message:empty {
            height: 8px;
        }
    }
    .form {
        &.inline-form {
            .control-group {
                align-items: baseline;
            }
        }
    }

    .config-block {
        flex-wrap: wrap;
        width: 50%;
    }
    .fee-config-wrapper {
        width: 100%;
    }
    .group-title {
        @include details-title();
        margin-bottom: 24px;
        +.subtitle {
            padding-left: 8px;
            color: #737D82;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .fee-claim-type-group {
        .inline-wrapper {
            .group-selection {
                &>div {
                    max-width: 100%;
                }
                .form-check-label {
                    margin-right: 0;

                    input[type="radio"] + span {
                        max-width: calc(100% - 28px);
                        text-wrap: initial;
                        height: auto;
                    }
                }
            }
        }
    }

    .search-block {
        flex-wrap: wrap;
        align-self: start;
        width: 50%;
        .search-rental {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }

    .control-group {
        &:not(.view-mode) {
            .caption {
                @include font-family-bold;
                width: 25%;
                transform: translateY(0);
                text-align: left;


                &:not(:empty):after {
                    content: initial;
                }

                &.required:after {
                    content: '*';
                }
            }
        }
        &.view-mode {
            .caption {
                @include font-family-bold;
                width: 25%;
                transform: translateY(0);
                text-align: left;
            }
        }


    }
    .comment-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: baseline;
        .control-group {
            width: calc(85% + 8px);
        }
    }
    .attachment-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

}
