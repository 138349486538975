@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";
@import "../../scss/mixins/media-query";

:fullscreen {
    html {
        margin:  -#{$main-desktop-header-tot-height} -#{$view-desktop-side-padding};
        height: calc(100vh + #{$main-desktop-header-tot-height})
    }
    .app-container {
        padding: 0;
    }
    .main-header {
        opacity: 0;
    }
    .btn-fullscreen {
        display: none;
    }
    .tv-monitor {
        margin-left: 0;
        margin-right: 0;
    }
}

.tv-monitor {
    background-color: $dark-indigo;
    min-height: 100vh;
    padding: 120px/$tv-screen-scale;
    text-align: right;
    margin-left: -#{$view-desktop-side-padding};
    margin-right: -#{$view-desktop-side-padding};
    padding-bottom: 240px/$tv-screen-scale;

    .btn-fullscreen {
        margin-bottom: (240px)/$tv-screen-scale;
    }
    .col-left {
        width: calc(50% - #{120px/$tv-screen-scale} - #{$tv-col-gap/$tv-screen-scale});
        text-align: left;
        flex-wrap: wrap;
    }
    .col-right {
        width: calc(50% - #{120px/$tv-screen-scale} - #{$tv-col-gap/$tv-screen-scale});
        flex-direction: column;
    }

    .block-title {
        color: $white;
        font-size: 78px/$tv-screen-scale;
        margin-bottom: 60px/$tv-screen-scale;
        @include font-family-bold();
        width: 100%;
    }

    .service-level {
        flex-wrap: wrap;
        margin-top: -40px/2/$tv-screen-scale;
        align-items: baseline;
        margin-right: -2*40px/$tv-screen-scale;
        justify-content: flex-start;
    }
    .single-SL {
        border: 1.8px/$tv-screen-scale solid rgba($white, 0.5);
        box-shadow: 0 0 18px/$tv-screen-scale  0 rgba(#000, 0.1);
        background-color: $dull-blue;
        color: $white;
        padding: 42px/$tv-screen-scale
                 40px/$tv-screen-scale
                 40px/$tv-screen-scale
                 40px/$tv-screen-scale;
        margin: (40px/2/$tv-screen-scale)-1
                (40px/$tv-screen-scale)-1
                (40px/2/$tv-screen-scale)-1
                0;
        min-width: max-content;
        max-width: calc(100% / 3 - #{40px/$tv-screen-scale});
        flex: 1;

        &:nth-child(6) {
            margin-bottom: 9px+(274px/$tv-screen-scale);
        }

        .info {
            width: auto;
            padding-right: 75px/$tv-screen-scale;
            .title {
                color: $white;
                font-size: 42px/$tv-screen-scale;
                line-height: 42px/$tv-screen-scale;
                margin-bottom: 32px/$tv-screen-scale;
            }
        }
        .dash-info {
            padding: 8px/$tv-screen-scale 0;
            min-width: 230px/$tv-screen-scale;
            height: 49px/$tv-screen-scale;

            &:not(:last-child) {
                border-bottom: 2px/$tv-screen-scale solid rgba($white, 0.1);
            }
            .dash-title {
                color: $white;
                font-size: #{$minimal-font-size}px;
                zoom: 28/$tv-screen-scale/$minimal-font-size;
                line-height: 33px/$tv-screen-scale;
            }
            .dash-value {
                color: $white;
                font-size: 35.5px/$tv-screen-scale;
                line-height: 41px/$tv-screen-scale;
            }
        }
        .chart {
            padding: 39.3px/$tv-screen-scale 27.3px/$tv-screen-scale 40px/$tv-screen-scale;
            background-color: rgba(#000, 0.2);
            min-width: 213px/$tv-screen-scale;
            margin: -42px/$tv-screen-scale
                    -40px/$tv-screen-scale
                    -40px/$tv-screen-scale
                    -40px/$tv-screen-scale;

            .chart-title {
                font-size: #{$minimal-font-size}px;
                zoom: 24/$tv-screen-scale/$minimal-font-size;
                line-height: normal;
            }
            .gauge {
                margin-bottom: -8px/$tv-screen-scale;
            }
        }
    }
}

