@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.warning-modal {
    text-align: left;

    .modal-box {
        .modal-title {
            z-index: 1;
        }
    }
    .warning {
        display: flex;
    }
    .warning-message {
        size: 16px;
        line-height: 28px;
        color: $charcoal;
        margin-bottom: 40px;
        word-break: normal;
        max-width: 640px;
    }
}
