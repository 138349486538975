/*
Single Service Level
*/
@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";

.single-SL {
    display: flex;

    .info {
        width: 135px;
        flex-grow: 1;
        padding-right: 52px;

        .title {
            margin-bottom: 13px;
        }

        .dash-info:last-child {
            padding-bottom: 0;
        }
    }
    .chart {
        min-width: 144px;
        margin: -24px;
        padding: 20px 20px 24px 20px;
        text-align: center;
        background-color: $gray98;

        .gauge {
            margin-bottom: 8px;
        }

        .chart-title {
            color: $deep-sky-blue;
            @include font-family-medium();
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            &.0275ff {
                color: $deep-sky-blue;
            }
            &.ffffff {
                color: $white;
            }
            &.warning {
                color: $selective-yellow;
            }
        }
    }
}
