@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";


.info-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px;
    margin: 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background: $white;

    .title {
        @include font-family-bold();
        color: $dull-blue;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 19px;
        text-align: left;
        width: 100%;
    }
}
