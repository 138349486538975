@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";
@import "../../scss/setup/variable";

.ad-order {
    height: 100%;

    .brief-intro {
        @include font-family-default();
        font-size: 14px;
        line-height:40px;
        color: $charcoal-grey;
        text-align: left;
        margin-bottom: 10px;
    }

    .main-content-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: calc(100% - 100px);

        @include xs() {
            display: block;
        }
    }

    .orderable-ad-list {
        height: 100%;
        width: 100%;
        transition: width .3s ease-out;
        transform-origin: left;

        .green {
            color: $shamrock;
        }
    }
}