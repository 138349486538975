@import "../../../scss/mixins/util";
@import "../../../scss/setup/variable";


.violate-rental {
    flex: 1 1 100%;
    flex-wrap: wrap;
    max-width: 100%;

    .control-group {
        &:not(.view-mode) {
            .caption {
                @include font-family-bold;
                width: 25%;
                transform: translateY(0);
                text-align: left;


                &:not(:empty):after {
                    content: initial;
                }

                &.required:after {
                    content: '*';
                }
            }
        }
    }

    .violate-rental-fields {
        flex-wrap: wrap;
        flex: 1 1 100%;
        border: 2px solid #F1F3F3;
        padding: 16px;
        gap: 24px;
    }
    .field-item-block {
        width: 100%;
        gap: 16px;
        &:not(:last-child) {
            border-bottom: 1px solid #F1F3F3;
        }
        .violate-btn {
            margin-top: -32px;
        }
    }
    .field-item-group {
        flex-direction: column;
        width: 100%;
        gap: 8px;

        .control-wrapper {
            width: 75%;
        }
    }

    .result-block {
        padding: 24px;
        gap: 24px;
        background-color: #F1F3F3;
        width: 100%;

        .search-hint {
            width: 100%;
            margin-top: -12px;
            margin-bottom: 24px;
            font-weight: 500;
            font-size: 14px;
            color: #737D82;
            line-height: 24px;
        }

        .search-result-msg {
            font-weight: 500;
            line-height: 19.6px;
            &.warning {
                color: $strawberry;
            }
        }

        .control-group {
            &.view-mode {
                line-height: 28px;
                margin-bottom: 8px;
            }
        }
        .rental-info {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            .control-group {
                &.view-mode {
                    align-items: center;
                }

            }

        }
    }
}
