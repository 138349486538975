/*
Dots Overall Service Level
*/
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.dots-overall-SL {
    display: flex;

    .info {
        min-width: 135px;
        flex-grow: 1;
        padding-right: 52px;
        max-width: calc(100% - 156px);

        .title {
            margin-bottom: 23px;
        }

        .dash-info:last-child {
            padding-bottom: 0;
        }
    }
    .chart {
        min-width: 180px;
        text-align: center;
        margin: -24px;
        background-color: $gray98;

        @media (max-width: 1400px) {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

        .gauge {
            margin-bottom: 8px;
        }

        .title {
            color: $deep-sky-blue;
            @include font-family-medium();
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            line-height: 19px;

            &.warning {
                color: $selective-yellow;
            }
        }

        .service-level {
            padding: 28px 24px 0 26px;
            @media (max-width: 1400px) {
                padding-top: 0;
            }

        }
    }
    .single-bar {
        width: 100%;
        align-items: flex-end;
    }
}
