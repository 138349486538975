@import "../../scss/mixins/media-query";

.parking-check {
    .modal-title {
        z-index: 1;
        margin-bottom: 24px;
    }
    .map-wrapper {
        padding: 8px 8px 16px;
        .info-grid {
            display: grid;
            font-size: 14px;
            grid-template-columns: 25% auto;
            margin: 8px 0;
            grid-row-gap: 8px;
            .profile-caption {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.71;
                margin: 0px;
                @include sm() {
                    font-size: 16px;
                }
            }
            .profile-item {
                flex: 1 1;
                display: flex;
                text-align: left;
                word-break: break-all;
                align-items: center;
            }
            @include sm() {
                font-size: 16px;
            }
        }
        .map-view {
            height: 480px!important;
        }
    }
}
