@import "../../scss/mixins/util";
@import "../../scss/setup/variable";

.additionalFeeDetail {
    .navigation-title {
        font-weight: 400;
    }
    .fee-detail {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .control-group {
        &:not(.view-mode) {
            .caption {
                @include font-family-bold;
                width: 25%;
                transform: translateY(0);
                text-align: left;


                &:not(:empty):after {
                    content: initial;
                }

                &.required:after {
                    content: '*';
                }
            }
        }
    }
    .fee-functions {
        gap: 12px;
    }
    .details-title {
        @include details-title();
        font-weight: 500;
    }
    .cards {
            .detail-form {
                width: 100%;
            }
    }
    .card {
        &.column {
            border: none;
            padding: 0;
            width: 50%;
        }
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .control-group {
        @at-root .detail-form & {
            align-items: center;
        }

        .caption {
            @include font-family-bold;
            width: 25%;
            transform: translateY(0);
            text-align: left;
        }

    }
    .detail-form {
        .control-group {
            align-items: baseline;
        }
    }
    .group-title {
        @include details-title();
        margin-bottom: 16px;
        +.subtitle {
            padding-left: 8px;
            color: #737D82;
            font-weight: 500;
            font-size: 14px;
        }
    }
    .status-related-time {
        &::before {
            content: '(';
        }
        &::after {
            content: ')';
        }
    }
    .comment-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: baseline;
        .control-group {
            width: calc(85% + 8px);
        }
    }
    .attachment-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }

    .btn-print-fee {
        &.btn-primary {
            line-height: 2.5;
            &.disabled {
                line-height: 2.2;
            }
        }

    }
}
